
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

import {
  LMap,
  LIcon,
  LTileLayer,
  LMarker,
  LControlLayers,
  LControlZoom,
  LTooltip,
  LPolygon,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";

export default defineComponent({
  name: "Kadermaps",
  components: {
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LControlLayers,
    LControlZoom,
    LTooltip,
    LPolygon,
  },
  setup() {
    setCurrentPageTitle("Peta Sebaran Kader");

    const store = useStore();
    // const router = useRouter();
    store.dispatch("AllTerritory", { type: 4 });
    // store.dispatch("AllCadreDashboard");

    const center = ref([-6.304159, 106.724997]);

    const zoom = ref(12);

    const iconSize = ref([50, 50]);

    const path = window.location.origin + "/";
    const icon = path + "other/png/vuesax-bold-user-tag.png";

    const markers = computed(() => store.state.CadreDashboardModule.all);
    const kecamatan = computed(() => store.state.TerritoryModule.kecamatan);
    const kelurahan = computed(() => store.state.TerritoryModule.kelurahan);
    const rw = computed(() => store.state.TerritoryModule.rw);

    const form = ref({
      kecamatan: "",
      kelurahan: "",
      rw: "",
    });

    const getKecamatan = (el) => {
      form.value.kelurahan = "";
      if (el.target.value) {
        store.dispatch("AllTerritory", { type: 5, parent_id: el.target.value });
        updateDataWilayah(el.target.value);
      } else {
        updateTanpaWilayah();
        store.commit("SET_ALL_TERRITORY", { type: 5, territories: [] });
      }
    };

    const getKelurahan = (el) => {
      if (el.target.value) {
        // store.dispatch("AllTerritory", { type: 6, parent_id: el.target.value });
        updateDataWilayah(el.target.value);
      } else {
        // store.commit("SET_ALL_TERRITORY", { type: 6, territories: [] });
        updateDataWilayah(form.value.kecamatan);
      }
    };

     const getRw = (el) => {
      form.value.rw = "";
      if (el.target.value) {
        // store.dispatch("AllTerritory", { type: 7, parent_id: el.target.value });
        updateDataWilayah(el.target.value);
      } else {
        updateDataWilayah(form.value.kelurahan);
        // store.commit("SET_ALL_TERRITORY", { type: 7, territories: [] });
      }
    };

    const updateDataWilayah = (wilayah) => {
      store.dispatch("AllCadreDashboard", {
        territory_id: wilayah,
      });
    };
    const updateTanpaWilayah = () => {
      store.dispatch("AllCadreDashboard");
    };

    return {
      center,
      markers,
      icon,
      zoom,
      kecamatan,
      kelurahan,
      rw,
      form,
      iconSize,
      getKecamatan,
      getKelurahan,
      getRw,
    };
  },
});
